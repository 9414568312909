<template>
  <div class="header">
    <div class="logo">
      <img src="../assets/logo.png" alt srcset />
    </div>
    <div class="spread">
      <a href="javascript:void(0)" @click="showPopup">
        <img src="../assets/icon-wspread.png" alt />
      </a>
    </div>
    <van-popup v-model="show" position="top" :style="{ height: '30rem'}">
      <div style="padding:2rem 1rem">
        <van-grid :border="false" :column-num="4" :gutter="8">
          <van-grid-item
            v-for="(item,index) in navList"
            :key="'n'+index"
            class="popnav-item"
            @click="jumpPage(item)"
          >{{ item.title }}</van-grid-item>
        </van-grid>
      </div>
    </van-popup>
  </div>
</template>
  <script>
export default {
  name: "HeadNav",
  props: {
    active: {
      type: Number,
      default: 0
    }
  },
  computed: {
    number: {
      get() {
        return parseInt(this.$props["active"]);
      },
      set(val) {
        return val;
      }
    }
  },
  data() {
    return {
      show: false,
      navList: [],
      actived: 3
    };
  },
  created() {
    this.loadMenu();
  },
  methods: {
    loadMenu: function() {
      var that = this;
      that.$api.getMenudata().then(res => {
        if (res.status == "success") {
          that.navList = res.data;
        }
      });
    },
    jumpPage(item) {
      this.$router.push({
        path: item.href,
        query: item.query
      });
    },
    showPopup: function() {
      this.show = true;
    }
  }
};
</script>
  <style lang="less" scoped="">
.header {
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
  position: relative;
}
.logo {
  height: 5rem;
  background: #b70903;
  padding: 1rem;
  text-align: left;
}
.logo img {
  height: 5rem;
  vertical-align: top;
}
.spread {
  width: 5rem;
  height: 5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.spread a {
  width: 3rem;
  height: 3rem;
  padding: 1rem;
  display: block;
}
.spread img {
  width: 3rem;
  height: 3rem;
}

/deep/.van-grid-item .van-grid-item__content {
  padding: 1rem;
  background: #e3e3e3;
  border-radius: 0.5rem;
  color: #666;
  font-size: 2.6rem;
}
</style>
  